<template>
  <router-view />
</template>

<script>
import context from '@/context'
import mediaModule from 'nette-media-module'
import frontModule from 'nette-front-module'
import NetteAjax from 'nette-base-module/js/nette/NetteAjax'

export default {
  setup () {
    NetteAjax.init()
    context.vueApp.use(frontModule, context)
    context.vueApp.use(mediaModule, context)
  },
}
</script>
